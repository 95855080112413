import React from 'react';
import ReactDOM from 'react-dom';

import Root from '../../containers/Root'

import theme from '../../assets/bundles/holdet/theme.json'
import config from '../../assets/bundles/holdet/config.json'

import translations from '../../translations';
import { store } from '../index'





var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

if( ios ) {
    if ( !standalone && safari ) {
        document.body.classList.add('ios-browser');
        
    } else if ( standalone && !safari ) {
        document.body.classList.add('ios-browser');
    } else if ( !standalone && !safari ) {
        document.body.classList.add('ios-webview');
    };
} else {
    document.body.classList.add('not-ios');
};

console.log('Mounting app...');

const rootEl = document.getElementById('sw-root');
if (rootEl) {
    
    ReactDOM.render(<Root store={ store } theme={ theme } config={ config } translations={ translations }></Root>, rootEl)
}

